<script setup lang="ts">
import { useGoogleAnalyticsPageView } from '~/composables/google-analytics'

const { $seoManager, $auth } = useNuxtApp()
const $route = useRoute()

const isAuthenticated = computed(() => $auth.store.isAuthenticated)

watch(isAuthenticated, (newValue) => {
  if (typeof window === 'undefined') return
  if (newValue) window.location.href = '/'
})

const getTabColor = (active) => (active ? 'bg-p-300' : 'bg-p-50')

const seo = $seoManager.generateSeoInfo({
  title: 'Login',
})
useHead(seo)
useGoogleAnalyticsPageView(seo.title)
</script>

<template>
  <div class="relative h-full grow justify-center">
    <!-- Mobile Buttons -->
    <section class="flex md:hidden">
      <LinkOrAnchor
        to="/login"
        :class="`w-1/2 p-3 text-center text-base font-semibold ${getTabColor(
          $route.path === '/login',
        )}`"
      >
        Login
      </LinkOrAnchor>
      <LinkOrAnchor
        to="/request-a-trial"
        :class="`w-1/2 p-3 text-center text-base font-semibold ${getTabColor(
          $route.path === '/request-a-trial',
        )}`"
      >
        Request a trial
      </LinkOrAnchor>
    </section>

    <!-- Cover -->
    <section
      class="left-0 top-0 h-40 bg-s-400 bg-cover bg-top md:absolute md:h-full md:w-1/2 md:pb-0"
      style="
        background-image: url('https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto/v1672543721/source-assets/login_cuvvs9.jpg?_i=AA');
      "
    />

    <!-- Form -->
    <section
      class="mx-auto max-w-content-container justify-end pb-48 pt-8 md:flex lg:py-48"
    >
      <div class="px-8 md:w-1/2">
        <LoginForm
          class="mx-auto max-h-[290px]"
          hide-title-on-mobile
          hide-sign-up-on-mobile
        />
      </div>
    </section>
  </div>
</template>
